import {
  Autocomplete,
  TextField,
  AutocompleteProps,
} from '@mui/material'
import { useQuery } from '@apollo/client'

import { Sku } from '@/lib/graphql/types'
import { CATALOG_LIST } from 'lib/graphql/listCatalog.ts'

type SkuAutocompleteProps = Omit<
  AutocompleteProps<
    {
      label: string
      value: string
    },
    false,
    false,
    false
  >,
  'renderInput' | 'options'
> & {
  organizationId: string
  archived: boolean
  usePlSku?: boolean
  withName?: boolean
  label?: string
}

export const SkuAutocomplete = (props: SkuAutocompleteProps) => {
  const { organizationId, archived, usePlSku, withName, ...autocompleteProps } = props
  const { data, loading } = useQuery(CATALOG_LIST, {
    variables: {
      limit: 500,
      offset: 0,
      orderBy: [{ sku: 'ASC' }],
      where: {
        brand: {
          organization: {
            id: {
              equals: organizationId,
            },
          },
        },
        archivedAt: archived ? undefined : null,
      },
    },
    fetchPolicy: 'no-cache',
  })

  const options: { label: string; value: string }[] = (data?.listSkus || []).map((sku: Sku) => ({
    label: `${usePlSku ? sku.skuWhiteLabel : sku.sku} ${withName ? ` - ${sku.skuFact.productName}` : ''}`,
    value: sku.id,
  }))

  const minWidth = withName ? '300px' : '200px'

  return (
    <Autocomplete
      {...autocompleteProps}
      sx={{ minWidth, m: 1 }}
      options={options}
      autoHighlight
      loading={loading}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => <TextField {...params} label={props.label} variant='standard' />}
    />
  )
}

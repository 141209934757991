import React from 'react'
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { CREATE_USER } from 'lib/graphql/createUser'
import { UPDATE_USER } from 'lib/graphql/updateUser'
import { useMutation, useReactiveVar } from '@apollo/client'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { useForm, SubmitHandler, Control } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { organizationId } from 'config/cache.ts'

type UserFieldProps = {
  id?: string
  email: string
}

type UserFormProps = {
  handleSubmit: SubmitHandler<any>
  isValid: boolean
  isSubmitting: boolean
  isDirty: boolean
  control: Control<UserFieldProps>
  submitText: string
  closeDialog: () => void
  id?: string
}

const validationSchema = Yup.object().shape({
  id: Yup.string().optional(),
  email: Yup.string().email().required('Required'),
})

const UserForm = ({
  handleSubmit,
  submitText,
  isDirty,
  isValid,
  isSubmitting,
  closeDialog,
  control,
  id,
}: UserFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <input type='hidden' name='id' value={id} />
      <DialogContent>
        <FormTextField
          name='email'
          label='Email'
          type='email'
          fullWidth
          variant='standard'
          control={control}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={() => {
            closeDialog()
          }}
        >
          Cancel
        </Button>
        <Button type='submit' disabled={isSubmitting || !(isDirty && isValid)}>
          {submitText}
        </Button>
      </DialogActions>
    </form>
  )
}

interface CreateOrUpdateUserProps {
  mode: 'create' | 'update'
  data: any
  onSuccess: () => void
  closeDialog: () => void
}

export const UserCreateOrUpdateDialog: React.FC<CreateOrUpdateUserProps> = ({
  mode,
  data,
  onSuccess,
  closeDialog,
}) => {
  const organizationIdVar = useReactiveVar(organizationId)

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted() {
      onSuccess()
    },
  })

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted() {
      onSuccess()
    },
  })

  const initialValues = {
    email: '',
  }

  const transformDataToFormValues = (data: any) => {
    return {
      id: data.id,
      email: data.email,
    }
  }

  const create = async (values: UserFieldProps) => {
    await createUser({
      variables: {
        data: {
          email: values.email.toLowerCase(),
          sub: '0',
          organizations: {
            connect: [
              {
                organizationId_userId: {
                  organizationId: organizationIdVar,
                  userId: '0',
                },
              },
            ],
          },
        },
      },
    })
  }

  const update = async (values: UserFieldProps) => {
    await updateUser({
      variables: {
        where: {
          id: values.id,
        },
        data: {
          email: values.email,
        },
      },
    })
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    getValues,
  } = useForm<UserFieldProps>({
    reValidateMode: 'onChange',
    resolver: yupResolver<UserFieldProps>(validationSchema),
    defaultValues: mode === 'update' ? transformDataToFormValues(data) : initialValues,
  })

  const onSubmit = async (values: UserFieldProps) => {
    mode === 'create' ? await create(values) : await update(values)
    closeDialog()
  }

  if (!data && mode === 'update') {
    return <></>
  }

  return (
    <>
      <DialogTitle>{mode === 'update' ? 'Update' : 'Add New'} User</DialogTitle>
      <UserForm
        handleSubmit={handleSubmit(onSubmit)}
        control={control}
        isDirty={isDirty}
        isValid={isValid}
        isSubmitting={isSubmitting}
        id={getValues('id')}
        submitText={mode === 'update' ? 'Update' : 'Add'}
        closeDialog={closeDialog}
      />
    </>
  )
}

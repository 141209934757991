import { useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import { LIST_SHOPIFY_STORE_CONFIGURATIONS } from 'lib/graphql/listShopifyStoreConfigurations.ts'
import { organizationId } from 'config/cache.ts'
import { ShopifyStoreConfiguration } from 'lib/graphql/types.ts'

const PAGE_SIZE_STORE = 50
export const useOrgStores = () => {
  const selectedOrganizationIdVar = useReactiveVar(organizationId)
  const [orgStores, setOrgStores] = useState<string[]>([])

  const { loading: loadingStores } = useQuery(LIST_SHOPIFY_STORE_CONFIGURATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organizationId: { equals: selectedOrganizationIdVar },
      },
      take: PAGE_SIZE_STORE,
    },
    skip: !selectedOrganizationIdVar,
    onCompleted: (data) => {
      setOrgStores(data?.listShopifyStoreConfigurations.map((x: ShopifyStoreConfiguration) => x.id))
    },
  })

  return { loading: loadingStores, stores: orgStores }
}

export type Organization = {
  id: string
  name: string
  brands: string[]
  createdAt: string
}

export type BrandAccount = {
  id: string
  name: string
  accountManagerName: string
  city: string
  state: string
  prop65Waiver: boolean
  sellsInCalifornia: boolean
}

export type Brand = {
  id: string
  name: string
  organization: Organization
  createdAt: string
  archivedAt: string
}

export type CustomerGroup = {
  id: string
  name: string
  autoAssignNewUsers: boolean
  customerExternalIds: string[]
}

export type Sku = {
  id: string
  sku: string
  brand: Brand
  brandId: string
  skuWhiteLabel?: string
  client: {
    name: string
  }
  skuFact: {
    isProp65: boolean
    privateLabelName: string
    productName: string
    size: string
    dimensions: string
  }
  createdAt: string
}

export type DesignerTemplate = {
  id: string
  templateId: string
  imageUrl: string
  displayName: string
  skuId: string
  sku: Sku
}
export type SkuListing = {
  id?: string
  title: string
  description?: string
  wholesaleAvailable: boolean
  wholesalePricing: number
  wholesaleMinQuantity: number
  wholesaleMaxQuantity?: number | null
  wholesaleBoxQuantity: number
  wholesaleCustomerGroups: Pick<CustomerGroup, 'id' | 'name'>[]

  retailAvailable: boolean
  retailPricing: number

  patientAvailable: boolean
  patientPricing: number

  whiteLabelAvailable: boolean
  whiteLabelPricing: number
  whiteLabelMinQuantity: number
  whiteLabelMaxQuantity?: number | null
  whiteLabelBoxQuantity: number
  whiteLabelCustomerGroups: Pick<CustomerGroup, 'id' | 'name'>[]

  shopifyStoreConfigurationId: string
  shopifyStoreConfiguration: Pick<ShopifyStoreConfiguration, 'id' | 'name'>

  skuId: string
  sku: Pick<Sku, 'id' | 'sku' | 'skuWhiteLabel'>
}

export type ShopifyStoreConfiguration = {
  id: string
  name: string
  domain: string
  brandedDomain: string
  fromEmail: string
  shopId: string
  adminApiToken: string
  clientId: string
  clientSecret: string
  assetsDomain: string
  configurationErrors: string
  configurationLastCheckedAt: string
  createdAt: string
  updatedAt: string
}

export enum externalStoreConfigurationType {
  Shopify = 'Shopify',
  Pressero = 'Pressero',
  Other = 'Other',
  Webhook = 'Webhook',
}
export type ExternalStoreConfigurationType = 'Shopify' | 'Pressero' | 'Other' | 'Webhook'

export type ExternalStoreConfiguration = {
  id?: string
  name?: string
  organizationId?: string
  storeType?: ExternalStoreConfigurationType
  description?: string
  domain?: string
  createdAt: string
  updatedAt: string
}

export type CheckShopifyStoreConfiguration = {
  configurationErrors?: string
  configurationLastCheckedAt?: string
  success: boolean
}

export type User = {
  id: string
  sub: string
  email: string
}

export type LabelApprovalWorkflowStatus =
  | 'Received'
  | 'InProgress'
  | 'Rejected'
  | 'Approved'
  | 'Cancelled'
  | 'ChangeRequested'

export type LabelApprovalWorkflowStep = {
  id: string
  stepNumber: number
  name: string
  workflowId: string
  assignedToId: string
  notes: string
  status: string
  createdAt: string
  updatedAt: string
  assignedTo: User
}

export type LabelApprovalWorkflowInstance = {
  id: string
  name: string
  status: LabelApprovalWorkflowStatus
  rejectionReason?: string
  instanceId?: string
  orderId: string
  metadata?: any
  shopifyStoreConfiguration: ShopifyStoreConfiguration
  activeStepNumber?: number
  createdAt: string
  updatedAt: string
  steps: LabelApprovalWorkflowStep[]
}

export type LabelApprovalWorkflowInstances = LabelApprovalWorkflowInstance[]

export type Lot = {
  id: string
  sku: Sku
  lotNumber: string
  expirationDate: string
  supplementFactMajorUuid: string
}

export type SupplementFact = {
  majorVersionUuid: string
  majorVersion: number
  minorVersion: number
}

export type PageInfo = {
  endCursor: string
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string
}

import { useEffect, useMemo } from 'react'
import _ from 'lodash'
import { useQuery, useReactiveVar, useLazyQuery } from '@apollo/client'

import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery.ts'
import { apolloClient } from 'lib/AppSyncProvider'
import { organizationId, stores } from 'config/cache.ts'
import { LineItem } from 'pages/VendorApp/Shop/types.ts'
import { SkuListing, PageInfo } from 'lib/graphql/types.ts'
import { Order } from 'lib/types.ts'
import { SKU_LISTINGS_LIST } from 'lib/graphql/listSkuListings.ts'

type StoreId = string
type Filter = string

const PAGE_SIZE_ORDERS = 100

type PageInfoMap = {
  [key: string]: PageInfo
}

type OrdersMap = {
  [key: string]: Order[]
}

export const useBillingData = ({ orgStores, filterQuery }: { orgStores: StoreId[]; filterQuery: Filter }) => {
  const storesVar = useReactiveVar(stores)
  const { data: skuData, loading: loadingSkuListings } = useQuery(SKU_LISTINGS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      organizationId: { id: organizationId },
      offset: 0,
      limit: 500,
    },
  })

  const [lazyShopifyQuery, { loading: loadingShopify }] = useLazyQuery(PROXY_SHOPIFY_QUERY)

  const results = orgStores?.map((storeId) => {
    return apolloClient.readQuery({
      query: PROXY_SHOPIFY_QUERY,
      // Provide any required variables in this object.
      // Variables of mismatched types will return `null`.
      variables: {
        query: 'orders',
        shopifyStoreId: storeId,
        variables: JSON.stringify({
          first: 100,
          reverse: true,
          query: filterQuery,
        }),
      },
    })
  })

  const pageInfoByStore: PageInfoMap = useMemo(
    () =>
      orgStores.reduce(
        (prevValue, storeId, index) => ({
          ...prevValue,
          [storeId]: results?.[index]?.proxyShopifyQuery?.orders?.pageInfo,
        }),
        {}
      ) || {},
    [orgStores, results]
  )

  useEffect(() => {
    if (orgStores.length === 0) return
    if (!filterQuery) return
    if (!orgStores) return
    orgStores.forEach((storeId) => {
      if (!pageInfoByStore?.[storeId] || pageInfoByStore?.[storeId]?.hasNextPage) {
        lazyShopifyQuery({
          fetchPolicy: 'cache-and-network',
          notifyOnNetworkStatusChange: true,
          variables: {
            query: 'orders',
            shopifyStoreId: storeId,
            variables: JSON.stringify({
              first: PAGE_SIZE_ORDERS,
              reverse: true,
              query: filterQuery,
              after: pageInfoByStore?.[storeId]?.endCursor,
            }),
          },
        })
      }
    })
  }, [orgStores, pageInfoByStore, lazyShopifyQuery, filterQuery])

  const resultsByStore: OrdersMap =
    orgStores?.reduce(
      (prevValue, storeId, index) => ({
        ...prevValue,
        [storeId]: _.flatten(
          results?.[index]?.proxyShopifyQuery.orders?.data?.map((node: any) => {
            return node.lineItems.nodes.map((lineItem: LineItem, index: number) => {
              return {
                ...node,
                id: `${node.id}-${index}`,
                shopifyId: node.id,
                lineItem: {
                  ...lineItem,
                  productName: lineItem.customAttributes.find((x) => x.key === 'Product Name')?.value,
                  serviceFeePercent:
                    !['PAID', 'PARTIALLY_REFUNDED'].includes(node.displayFinancialStatus) || node.cancelledAt
                      ? 0
                      : lineItem.variantTitle === 'White label'
                      ? 0.2
                      : 0.15,
                },
              }
            })
          })
        ),
      }),
      {}
    ) || {}

  const resultOrders = resultsByStore
    ? _.flatten(
        Object.keys(resultsByStore).map((storeId) => {
          return (resultsByStore[storeId] || []).map((order: any) => ({
            ...order,
            id: order.id,
            storeId: storeId,
            storeName: storesVar?.find((x) => x.id === storeId)?.name,
            wholesalePricing: parseFloat(
              skuData?.listSkuListings.find(
                (x: SkuListing) =>
                  x.sku.skuWhiteLabel === order.lineItem.sku || x.sku.sku === order.lineItem.sku
              )?.wholesalePricing || '0'
            ),
            serviceFeeAmount:
              (skuData?.listSkuListings.find(
                (x: SkuListing) =>
                  x.sku.skuWhiteLabel === order.lineItem.sku || x.sku.sku === order.lineItem.sku
              )?.wholesalePricing || 0) *
              (order.lineItem.serviceFeePercent || 0) *
              order.lineItem.currentQuantity,
          }))
        })
      )
    : []

  return { resultOrders, resultsByStore, loadingSkuListings, loadingShopify }
}
